export const headerMenuItem = [
  { label: 'Bot Builder', path: '/bot-builder' },
  { label: 'Orders', path: '/orders-history' },
  { label: 'Signal Strategy', path: '/signal-strategy' },
  { label: 'Back Testing', path: '/back-testing' },
  { label: 'FAQ', path: '/faq' },
  // {
  //   label: 'Account',
  //   path: "/",
  //   subItems: [
  //     { label: 'Balance', path: '/balance' },
  //     { label: 'Settings', path: '/settings' },
  //   ],
  // },
];