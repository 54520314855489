import { lazy } from "react";

import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';

import Layout from "../../components/Layout/Layout";
import UserOnline from "../../UserOnline";
import UserOffline from "../../UserOffline";

import { ErrorPage, Login, LandingPage, Career } from '../index';

const Home = lazy(() => import('../Home/Home'));
const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const Settings = lazy(() => import('../Settings/Settings'));
const BotBuilder = lazy(() => import('../BotBuilder/BotBuilder'));
const CreateBotPage = lazy(() => import('../CreateBotPage/CreateBotPage'));
const BackTesting = lazy(() => import('../BackTesting/BackTesting'));
const InstancePage = lazy(() => import('../BackTesting/Instance/InstancePage'));
const OrdersHistory = lazy(() => import('../OrdersHistory/OrdersHistory'));
const BotPage = lazy(() => import('../BotPage/BotPage'));
const SignalStrategyMain = lazy(() => import('../SignalStrategy/SignalStrategyMain'));
const SignalStrategy = lazy(() => import('../SignalStrategy/SignalStrategy'));
const Account = lazy(() => import('../Account/Account'));
const ApiKey = lazy(() => import('../ApiKey/ApiKey'));
const FAQ = lazy(() => import('../FAQ/FAQ'));

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<UserOffline><Login /></UserOffline>} />
            <Route path="/" element={<UserOffline><LandingPage /></UserOffline>} />
            <Route element={<UserOnline><Layout /></UserOnline>}>
                <Route path="/bot-builder" element={<BotBuilder />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/account" element={<Account />} />
                <Route path="/account/apikey" element={<ApiKey />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/bot-builder/create-bot" element={<CreateBotPage />} />
                <Route path="/bot-builder/clone-bot/:id" element={<CreateBotPage />} />
                <Route path="/back-testing" element={<BackTesting />} />
                <Route path="/back-testing/instance/:id" element={<InstancePage />} />
                <Route path="/orders-history" element={<OrdersHistory />} />
                <Route path="/bot-builder/bot/:id" element={<BotPage />} />
                <Route path="/signal-strategy" element={<SignalStrategyMain />} />
                <Route path="/signal-strategy/create" element={<SignalStrategy />} />
                <Route path="/signal-strategy/strategy/:id" element={<SignalStrategy />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/faq/:id" element={<FAQ />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
            <Route path="/career" element={<UserOffline><Career /></UserOffline>} />
        </Routes>
    );
};

export default AppRoutes;