import { API_URL } from "../../constants";
import api from "../../api";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getSingleBotData = createAsyncThunk(
    'botInstance/getSingleBotData',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.get(`${API_URL}/bot/instance/config/${id}`);
            localStorage.setItem('bot_per_uuid', res.data?.uuid);
            return res.data;
        } catch (error) {
            localStorage.removeItem('bot_per_uuid');
            console.error(error);
            return rejectWithValue(error.message);
        }
    }
);

const singleBot = createSlice({
    name: 'singleBot',
    initialState: {
        data: [],
        loading: false,
        error: null
    },
    reducers: {
        resetData: (state) => {
            state.data = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSingleBotData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleBotData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                state.error = null;
            })
            .addCase(getSingleBotData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
});

export const { resetData } = singleBot.actions;
export const singleDataSelector = (state) => state.singleBot;

export default singleBot.reducer;
