import { API_URL } from "../../constants";
import api from "../../api";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getAccount = createAsyncThunk(
  'getAccount',
  async () => {
      try {
          const res = await api.get(`${API_URL}/account`);
          return res.data;
      } catch (error) {
          console.log(error);
      }
  }
);

export const getAllBalance = createAsyncThunk(
  'getAllBalance',
  async () => {
      try {
          const res = await api.get(`${API_URL}/account/allbalance`);
          return res.data;
      } catch (error) {
          console.log(error);
      }
  }
);

export const getCoins = createAsyncThunk(
  'getCoins',
  async () => {
    try {
      const res = await api.get(`${API_URL}/coins/currency`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);


const account = createSlice({
    name: "account",
    initialState: {
        accountData: null,
        allBalanceData: null,
        coins: null,
        isLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(getAccount.pending, (state) => {
              state.loading = true;
          })
          .addCase(getAccount.fulfilled, (state, action) => {
              state.loading = false;
              state.accountData = action.payload;
              state.error = null;
          })
          .addCase(getAccount.rejected, (state, action) => {
              state.loading = false;
          })
          .addCase(getAllBalance.pending, (state) => {
              state.loading = true;
          })
          .addCase(getAllBalance.fulfilled, (state, action) => {
              state.loading = false;
              state.allBalanceData = action.payload;
              state.error = null;
          })
          .addCase(getAllBalance.rejected, (state, action) => {
              state.loading = false;
          })
          .addCase(getCoins.pending, (state) => {
            state.loading = true;
          })
          .addCase(getCoins.fulfilled, (state, action) => {
            state.loading = false;
            state.coins = action.payload;
            state.error = null;
          })
          .addCase(getCoins.rejected, (state, action) => {
            state.loading = false;
          });
    }
});

export const accountSelector = (state) => state?.account;
export default account.reducer;
