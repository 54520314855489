import React, {useState} from 'react';
import LoginForm from "./LoginForm";
import brandLogo from "./../../assets/images/brand-logo.png"
import {API_URL} from "../../constants";
import api from "../../api";
import {useNavigate} from "react-router-dom";

const Login = () => {
    const navigate = useNavigate()
    const [isError, setIsError] = useState(false)
    
    const login = ({username, password}) => {
        api.post(`${API_URL}/login`, { username, password })
            .then(response => {
                const { access_token } = response.data
                localStorage.setItem('access_token', access_token)
                navigate('/')
            })
            .catch(error => {
                if (error.response.status === 401){
                    setIsError(true)
                }
            })
    }

    return (
        <div className="h-screen login">
            <div className="flex items-center justify-center w-full h-full flex-col">
                <div className="rounded-2xl p-10 login-wrapper">
                    <div className="login-header flex justify-center">
                        <img src={brandLogo} alt="" className="h-12"/>
                    </div>
                    <div className="flex mt-10">
                        <div className="login-form">
                            <p className="mb-10 capitalize">Please enter your credentials to access your account</p>
                            <LoginForm
                                login={login}
                                error={isError}
                            />
                        </div>
                    </div>
                </div>
                <span className='mt-5 cursor-pointer underline' onClick={() => navigate('/')}>Back to Home</span>
            </div>
        </div>
    );
};

export default Login;
