import {API_URL} from "../../constants";
import api from "../../api";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const getIndicators = createAsyncThunk(
    'getIndicators',
    async () => {
        try {
            const res = await api.get(`${API_URL}/indicators`)
            return res.data

        } catch (error) {
            console.error(error)
        }
    })

const indicators = createSlice({
    name: "indicators",
    initialState: {
        data: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: {
        [getIndicators.pending]: (state) => {
            state.loading = true
        },
        [getIndicators.fulfilled]: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        [getIndicators.rejected]: (state, action) => {
            state.loading = false
            state.error = action.error
        },
    }
})

export const indicatorsSelector = (state) => state.indicators?.data
export default indicators.reducer