import axios from 'axios';
import { toast } from 'react-toastify';
import {API_URL} from "./constants";
import history from "./history";

const $api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

$api.interceptors.request.use(
    config => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;

        if (!localStorage.getItem('access_token')) {
            delete config.headers.Authorization;
        }

        return config;
    },
    error => {
        toast.error('error');

        throw error;
    },
);

$api.interceptors.response.use(
    config => {
        if (config.data) {
            const { message } = config.data;
            if (message) {
                toast.success(message);
            }
        }

        return config;
    },
    async error => {
        if (error.response) {
            toast.error(error.response.data.message);
            if (error.response.status === 401) {
                localStorage.removeItem('access_token');
                history.push('/')
            }
        }


        throw error;
    },
);

export default $api;