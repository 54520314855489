import {API_URL} from "../../constants";
import api from "../../api";
import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {string} from "prop-types";

export const getBackTestingInstance = createAsyncThunk(
    'getBackTestingInstance',
    async (_, {dispatch}) => {
        try {
            const res = await api.get(`${API_URL}/backtesting/instance/config`)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const addBackTestingInstance = createAsyncThunk(
    'addBackTestingInstance',
    async (data, {dispatch, rejectWithValue}) => {
        try {
            const res = await api.post(`${API_URL}/backtesting/instance/config`, data)
            return res.data
        } catch (error) {
            if (!error.response) {
                throw error;
            }

            return rejectWithValue(error.response);
        }
    }
)

export const deleteBackTestingInstance = createAsyncThunk(
    'deleteBackTestingInstance',
    async (id, {dispatch, rejectWithValue}) => {
        try {
            await api.delete(`${API_URL}/backtesting/instance/config/${id}`)
            return id
        } catch (error) {
            if (!error.response) {
                throw error;
            }

            return rejectWithValue(id);
        }
    }
)

export const deleteBackTestingInstanceByIdList = createAsyncThunk(
    'deleteBackTestingInstanceByIdList',
    async (idList, { dispatch }) => {
        try {
            const res = await api.delete(`${API_URL}/backtesting/instance/config`, { data: {id_list: idList} });
            return res.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);

export const playBackTestingInstance = createAsyncThunk(
    'playBackTestingInstance',
    async (id, { dispatch }) => {
        try {
            await api.post(`${API_URL}/backtesting/instance/run/${id}`, { backtesting_status: true });
            return id;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);

export const getBackTestingResult = createAsyncThunk(
    'getBackTestingResult',
    async (id) => {
        try {
            const res = await api.get(`${API_URL}/backtesting/instance/reports/${id}`)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const getBackTestingChartData = createAsyncThunk(
    'getBackTestingChartData',
    async ({id, name}) => {
        try {
            const res = await api.get(`${API_URL}/backtesting/instance/reports/plot/${name}/${id}`)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)


const collectorAdapter = createEntityAdapter({})
export const backTestingInstanceSelector = (state) => state?.backTestingInstance
export const {selectAll: btInstanceSelector, selectById: instancesBySelector} = collectorAdapter.getSelectors(backTestingInstanceSelector)

const backTestingInstance = createSlice({
    name: "backTestingInstance",
    initialState: collectorAdapter.getInitialState({
        isLoading: true,
        isLoadingReport: false,
        resultData: {},
        chartData: {},
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBackTestingInstance.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getBackTestingInstance.fulfilled, (state, action) => {
                collectorAdapter.setAll(state, action?.payload)
                state.isLoading = false
            })
            .addCase(getBackTestingInstance.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(addBackTestingInstance.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(addBackTestingInstance.fulfilled, (state, action) => {
                collectorAdapter.addOne(state, action.payload)
                state.isLoading = false
            })
            .addCase(addBackTestingInstance.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(deleteBackTestingInstance.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteBackTestingInstance.fulfilled, (state, action) => {
                collectorAdapter.removeOne(state, action.payload.id)
                state.isLoading = false
            })
            .addCase(deleteBackTestingInstance.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(playBackTestingInstance.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(playBackTestingInstance.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(playBackTestingInstance.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getBackTestingResult.pending, (state) => {
                state.isLoadingReport = true;
            })
            .addCase(getBackTestingResult.fulfilled, (state, action) => {
                state.chartData = action.meta.arg === state.chartData.id ? state.chartData : {}
                state.resultData = action.payload;
                state.isLoadingReport = false
            })
            .addCase(getBackTestingResult.rejected, (state) => {
                state.isLoadingReport = false;
            })
            .addCase(getBackTestingChartData.pending, (state) => {
                state.isLoading = false;
            })
            .addCase(getBackTestingChartData.fulfilled, (state, action) => {
                state.resultData = action.meta.arg.id === state.resultData.id ? state.resultData : {}
                state.chartData = {id: action.meta.arg.id, name: action.payload}
                state.isLoading = false
            })
            .addCase(getBackTestingChartData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(deleteBackTestingInstanceByIdList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteBackTestingInstanceByIdList.fulfilled, (state, action) => {
                collectorAdapter.setAll(state, action.payload);
                state.isLoading = false;
            })
            .addCase(deleteBackTestingInstanceByIdList.rejected, (state) => {
                state.isLoading = false;
            });
    }
})

export default backTestingInstance.reducer;



