import {combineReducers} from "redux";
import collector from "./slices/collectorSlice";
import statistics from "./slices/orderStatisticsSlice"
import botInstance from "./slices/botInstanceSlice"
import signalStrategy from  "./slices/signalStrategySclice"
import indicators from "./slices/indicatorsSlice"
import backTestingCollector from "./slices/backTestingCollectorSlice"
import currency from "./slices/currencySlice"
import exchange from "./slices/exchangeSlice"
import backTestingInstance from "./slices/backTestingInstanceSlice"
import account from "./slices/accountSlice"
import singleBot from "./slices/singleBotSlice";

const rootReducer = combineReducers({
    account,
    collector,
    statistics,
    botInstance,
    indicators,
    signalStrategy,
    backTestingCollector,
    backTestingInstance,
    currency,
    exchange,
    singleBot
})

export default rootReducer;