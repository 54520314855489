import { cardsToEarn } from "./consts"

const LandingEarn = () => {
    return (
        <section id="Guide" className="landing-earn">
            <div className="container">
                <h2><span className="highlight">Maximizing Alpha:</span> <br />Botico Guide to Profit  Optimization</h2>
                <div className="earn-cards">
                    {cardsToEarn?.map(cards => (
                        <div key={cards.id} className="earn-card">
                            <div className="card-wrapper">
                                <img src={cards.path} alt="" />
                                <div className="card-title">{cards.title}</div>
                                <div className="card-desc">{cards.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )   
}

export default LandingEarn;