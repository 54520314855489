import {API_URL} from "../../constants";
import api from "../../api";
import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';

export const getCurrency = createAsyncThunk(
    'getCurrency',
    async (_, {dispatch}) => {
        try {
            const res = await api.get(`${API_URL}/coins/bqcurrency`)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const addCurrency = createAsyncThunk(
    'addCurrency',
    async (data, {dispatch}) => {
        try {
            const res = await api.post(`${API_URL}/coins/bqcurrency`, { name: data })
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const deleteCurrency = createAsyncThunk(
    'deleteCurrency',
    async (id, {dispatch, rejectWithValue}) => {
        try {
            await api.delete(`${API_URL}/coins/bqcurrency/${id}`)
            return id
        } catch (error) {
            if (!error.response) {
                throw error;
            }

            return rejectWithValue(id);
        }
    }
)

const collectorAdapter = createEntityAdapter({})
export const currencySelectorAdapter = (state) => state?.currency
export const {selectAll: currencySelector} = collectorAdapter.getSelectors(currencySelectorAdapter)

const currency = createSlice({
    name: "currency",
    initialState: collectorAdapter.getInitialState({
        isLoading: true,
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCurrency.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getCurrency.fulfilled, (state, action) => {
                collectorAdapter?.setAll(state, action?.payload)
                state.isLoading = false
            })
            .addCase(getCurrency.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(addCurrency.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(addCurrency.fulfilled, (state, action) => {
                collectorAdapter?.addOne(state, action.payload)
                state.isLoading = false
            })
            .addCase(addCurrency.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(deleteCurrency.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteCurrency.fulfilled, (state, action) => {
                collectorAdapter.removeOne(state, action.payload.id)
                state.isLoading = false
            })
            .addCase(deleteCurrency.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export default currency.reducer;



