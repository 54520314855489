import iexchange from '../../assets/images/landing-page/i-exchange.png'
import ibot from '../../assets/images/landing-page/ai.png'
import dashboard from '../../assets/images/landing-page/dashboard.png'
import riskm from '../../assets/images/landing-page/riskm.png'
import signals from '../../assets/images/landing-page/signals.png'
import binance from "../../assets/images/landing-page/binancelogo.png"
import coinbase from "../../assets/images/landing-page/coinbaselogo.png"
import bybit from "../../assets/images/landing-page/bybitlogo.png"
import kucoin from "../../assets/images/landing-page/kucoinlogo.png"
import bitget from "../../assets/images/landing-page/bitgetlogo.png"

export const menuItems = ['About', 'Guide', 'FAQ'];

export const productTabs = [
    {
        id: 'tab-1',
        name: 'Trading Hub',
        content: 'Access multiple exchanges and assets in one place, streamlining your trading experience, auto-executing Spot and Futures markets trading 24/7.'
    },
    {
        id: 'tab-2',
        name: 'Signal Studio',
        content: 'Create unique trading signals with over 100 indicators, tailored to your strategy and risk tolerance. Backtest and refine your strategies to optimize performance.'
    },
    {
        id: 'tab-3',
        name: 'Real-Time Insight Engine',
        content: 'Get instant access to live market data, news, and analytics, empowering informed trading decisions.'
    },
    {
        id: 'tab-4',
        name: 'Risk Manager',
        content: 'Set your unique risk tolerance level, manage risk metrics. Maximize profit and minimize potential losses, with super-customizable Trailing Take-Profit and Risk per Trade functions.'
    },
    {
        id: 'tab-5',
        name: 'Performance Dashboard',
        content: 'Track, compare and refine your trading performance in real-time, with actionable insights and data-driven recommendations.'
    }
];


export const cardsToEarn = [
    {
        id: 'card-1',
        path: ibot,
        title: 'Create and customize your bots',
        description: "Use backtesting, paper trading or copy trading."
    },
    {
        id: 'card-2',
        path: riskm,
        title: 'Define your risk parameters',
        description: "Set your trading limits, use Dynamic Position Sizing with auto-calculated Risk Ratios."
    },
    {
        id: 'card-3',
        path: iexchange,
        title: 'One account for all Exchanges',
        description: "Connect API keys & Controll Access."
    },
    {
        id: 'card-4',
        path: signals,
        title: 'Signals Studio',
        description: "Mix & Match Indicators for Optimal trading decisions."
    },
    {
        id: 'card-5',
        path: dashboard,
        title: 'Unified Dashboard',
        description: "Monitor Signals, Track All Bots, Trades & Performance Metrics."
    },
]

export const exchangeEntities = [
    {
        name: "Binance",
        type: "spot",
        path: binance
    },
    {
        name: "Coinbase",
        type: "coming soon...",
        path: coinbase
    },
    {
        name: "Bybit",
        type: "coming soon...",
        path: bybit
    },
    {
        name: "KuCoin",
        type: "coming soon...",
        path: kucoin
    },
    {
        name: "Bitget",
        type: "coming soon...",
        path: bitget
    },
]

export const faqItems = [
    {
        question: 'What is Botico?',
        answer: 'Botico is a trading orchestration toolset that empowers users to monitor assets live, create custom signals, define risk metrics, test, automate, and track performance, copy and optimize their trading strategy. ',
    },
    {
        question: 'What is the Unified Dashboard?',
        answer: 'The Unified Dashboard is a centralized platform that provides a comprehensive view of your trading activity, allowing you to monitor signals, track all bots, trades, and performance metrics in one place.',
    },
    {
        question: 'Can I customize the indicators used in Signals Studio?',
        answer: 'Yes, Signals Studio offers unparalleled customization with over 100 indicators, enabling you to mix and match them to create unique signals tailored to your trading approach.',
    },
    {
        question: "Is Botico compatible with multiple exchanges?",
        answer: "Yes, Botico currently supports Binance, Coinbase, and ByBit, with plans to expand to 10+ exchanges at launch."
    },
    {
        question: "When will Botico be available?",
        answer: "Currently, a beta version is available for select users. The production version is expected to launch in Q4 2024. Stay tuned for updates and get ready to elevate your trading experience!"
    }
];


export const careerPositions = [
    {
        name: "Front-End Developer",
        responsibilities: "Develop and maintain user-friendly interfaces, Ensure seamless user experience, Collaborate with design and backend teams, Implement responsive design principles to ensure compatibility across devices, Optimize application performance for maximum speed and scalability, Troubleshoot and debug front-end issues.",
        requirements: "Proficiency in JavaScript, Typescript, React, redux-toolkit, RTK Query, Strong problem-solving skills and attention to detail, Ability to work in a fast-paced startup environment.",
        preferred: "",
        deadline: "Application Deadline: July 31, 2024",
        open: true,
        touch: "Send your CV and Cover letter (if any) to"
    },
    {
        name: "Back-End Developer",
        responsibilities: "Build and optimize server-side applications, Integrate with front-end components, Manage databases, Design and implement RESTful APIs, Ensure the security and scalability of backend systems, Monitor and maintain system performance and uptime.",
        requirements: "Proficiency in Python, Flask, and MySQL, Understanding of RESTful API design, Experience with cloud services (AWS and Azure), Strong knowledge of database management and optimization, Familiarity with tools like Docker.",
        preferred: "",
        deadline: "Application Deadline: July 31, 2024",
        open: true,
        touch: "Send your CV and Cover letter (if any) to"
    },
    {
        name: "Social Media Manager (SMM)",
        responsibilities: "Develop and execute social media strategies, Create engaging content, Manage community interactions, Monitor and analyze social media metrics to optimize campaigns, Stay updated on social media trends, Collaborate with marketing and design teams to ensure brand consistency.",
        requirements: "Proven experience in social media management, Excellent communication skills, Ability to analyze and report on social media metrics, Strong understanding of various social media platforms, Experience with social media management tools (e.g., Hootsuite, Buffer), Creativity and ability to generate innovative ideas for content.",
        preferred: "",
        deadline: "Application Deadline: July 31, 2024",
        open: true,
        touch: "Send your CV and Cover letter (if any) to"
    },
    {
        name: "UI/UX Designer",
        responsibilities: "Create visually appealing and user-friendly designs, Develop branding materials, Work closely with developers to implement design concepts, Conduct user research and testing to inform design decisions, Maintain design consistency across all digital platforms.",
        requirements: "Proficiency in design tools (eg., Sketch, Figma), Strong portfolio showcasing UI/UX design skills, Attention to detail, Ability to create wireframes, prototypes, and mockups, Experience with user-centered design methodologies, Strong communication and collaboration skills.",
        preferred: "",
        deadline: "July, 2024",
        open: false,
        touch: "Send your CV and Cover letter (if any) to"
    },
    {
        name: "Professional Trader",
        responsibilities: "Execute high-volume trades efficiently, Develop and test trading strategies, Monitor market trends and news, Manage trading risks, Collaborate to improve trading algorithms.",
        requirements: "Proven trading experience, Strong analytical and decision-making skills, Proficiency with trading platforms, Advanced risk management knowledge, Understanding of market structures and technical indicators.",
        preferred: "Degree in Finance, Economics, or related field.",
        deadline: "Jun, 2024",
        open: false
    },
    {
        name: "Infrastructure Architect",
        responsibilities: "Design and implement scalable system architectures for our trading platform, Ensure system reliability, security, and performance, Collaborate with development teams to integrate new features, Conduct system analysis and recommend improvements, Lead technical discussions and provide strategic input.",
        requirements: "Extensive experience in system architecture and design, Strong knowledge of cloud infrastructure (AWS and Azure), Proficiency in programming languages such as Python, Java is +, Experience with microservices architecture, Excellent problem-solving and analytical skills.",
        preferred: "Degree in Computer Science, Engineering, or related field, Professional certifications (AW Solutions Architect, etc.), Familiarity with blockchain technology and trading systems is also +.",
        deadline: "Jun, 2024",
        open: false
    }
]