import {API_URL} from "../../constants";
import api from "../../api";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const getOrderStatistics = createAsyncThunk(
    'getOrderStatistics',
    async () => {
        try {
            const res = await api.get(`${API_URL}/stats`)
            return res.data

        } catch (error) {
            console.error(error)
        }
    }
)

const statistics = createSlice({
    name: "orderStatistics",
    initialState: {
        data: {},
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: {
        [getOrderStatistics.pending]: (state) => {
            state.loading = true
        },
        [getOrderStatistics.fulfilled]: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        [getOrderStatistics.rejected]: (state, action) => {
            state.loading = false
            state.error = action.error
        },
    }
})


export const statisticsSelector = (state) => state.statistics?.data
export default statistics.reducer