import { useState } from 'react';
import { faqItems } from './consts';

const LandingFAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section id='FAQ' className="landing-faq">
            <h2>FAQ</h2>
            <div className="container">
                {faqItems.map((item, index) => (
                    <div key={index} className='faq-wrapper'>
                        <div className="faq">
                            <div className={`faq-header ${openIndex === index ? 'open' : ''}`} onClick={() => toggleFAQ(index)}>
                                <h3>{item.question}</h3>
                                <div className="toggle-icon">{openIndex === index ? '-' : '+'}</div>
                            </div>
                            {openIndex === index && (
                                <div className="faq-content">
                                    <p>{item.answer}</p>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default LandingFAQ;
