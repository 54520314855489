import {API_URL} from "../../constants";
import api from "../../api";
import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';

export const getCollectors = createAsyncThunk(
    'getCollectors',
    async (_, {dispatch}) => {
        try {
            const res = await api.get(`${API_URL}/collector/config`)
            return res.data.reverse()
        } catch (error) {
            console.log(error)
        }
    }
)

export const addCollector = createAsyncThunk(
    'addCollector',
    async (data, {dispatch}) => {
        try {
            const res = await api.post(`${API_URL}/collector/config`, data)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const editCollector = createAsyncThunk(
    'editCollector',
    async ({id, data}, {dispatch}) => {
        try {
            const res = await api.put(`${API_URL}/collector/config/${id}`, data)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const deleteCollector = createAsyncThunk(
    'deleteCollector',
    async (id, {dispatch, rejectWithValue}) => {
        try {
            await api.delete(`${API_URL}/collector/config/${id}`)
            return id
        } catch (error) {
            if (!error.response) {
                throw error;
            }

            return rejectWithValue(id);
        }
    }
)

const collectorAdapter = createEntityAdapter({})
export const collectorSelector = (state) => state?.collector
export const {selectAll: collectorsSelector, selectById: collectorsBySelector} = collectorAdapter.getSelectors(collectorSelector)

const collector = createSlice({
    name: "collector",
    initialState: collectorAdapter.getInitialState({
        isLoadingCollector: true,
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCollectors.pending, (state, action) => {
                state.isLoadingCollector = true
            })
            .addCase(getCollectors.fulfilled, (state, action) => {
                collectorAdapter?.setAll(state, action?.payload)
                state.isLoadingCollector = false
            })
            .addCase(getCollectors.rejected, (state, action) => {
                state.isLoadingCollector = false
            })
            .addCase(addCollector.pending, (state, action) => {
                state.isLoadingCollector = true
            })
            .addCase(addCollector.fulfilled, (state, action) => {
                collectorAdapter?.addOne(state, action.payload)
                state.isLoadingCollector = false
            })
            .addCase(addCollector.rejected, (state, action) => {
                state.isLoadingCollector = false
            })
            .addCase(editCollector.pending, (state, action) => {
                state.isLoadingCollector = true
            })
            .addCase(editCollector.fulfilled, (state, action) => {
                collectorAdapter.updateOne(state, {id: action.payload.id, changes: action.payload})
                state.isLoadingCollector = false
            })
            .addCase(editCollector.rejected, (state, action) => {
                state.isLoadingCollector = false
            })
            .addCase(deleteCollector.pending, (state, action) => {
                state.isLoadingCollector = true
            })
            .addCase(deleteCollector.fulfilled, (state, action) => {
                collectorAdapter.removeOne(state, action.payload.id)
                state.isLoadingCollector = false
            })
            .addCase(deleteCollector.rejected, (state, action) => {
                state.isLoadingCollector = false
            })
    }
})

export default collector.reducer;



