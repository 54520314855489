import Menu from '@mui/material/Menu';
import {NavLink} from "react-router-dom"
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ApiIcon from '@mui/icons-material/Api';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {copyText} from "../../utils";
import api from "../../api";
import {API_URL} from "../../constants";
import {useNavigate} from "react-router-dom";


const AccountMenu = ({open, onClose, anchorEl, account}) => {
    const accBalance = account?.accountData?.balance?.filter(x => x.currency === 'USDT')?.[0]
    const fixedBalance = accBalance ? Number(accBalance.balance_free).toFixed(2) : '0.00';

    const navigate = useNavigate()
    const logout = () => {
        api.post(`${API_URL}/logout`)
            .then(response => {
                localStorage.removeItem('access_token')
                navigate('/login')
            })
            .catch(error => console.error(error))
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
            >
                <div className="p-4">
                    <div>{`botico`}</div>
                    <div className="flex items-center text-brand-gray mb-3">
                        <span>uid: {`d11199b3c5b649788`}</span>
                        <IconButton className="p-0" onClick={copyText}>
                            <ContentCopyIcon className="text-brand-gray" sx={{height: 14, width: 14, ml: 1}}/>
                        </IconButton>
                    </div>
                    <MenuItem className="p-4 bg-primary-black rounded-xl items-start flex-col">
                        <NavLink to="/account" className="text-brand-gray">
                            <div className="text-sm mb-2">Balance Overview</div>
                            <div className="text-2xl font-bold">{`${fixedBalance} ${accBalance?.currency || 'USDT'}`}</div>
                            <div className="text-sm pt-1">Pnl for today:<span
                              className="text-neo"> +98%</span></div>
                        </NavLink>
                    </MenuItem>
                </div>
                <Divider />
                <MenuItem>
                    <ListItemIcon>
                        <ApiIcon fontSize="small" />
                    </ListItemIcon>
                    <NavLink to="/account/apikey">API MANAGEMENT</NavLink>
                </MenuItem>
                <MenuItem >
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    SETTINGS
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    LOGOUT
                </MenuItem>
            </Menu>
        </>
    );
};

export default AccountMenu;
