import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, IconButton, InputAdornment, Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
});

const LoginForm = ({ login, error }) => {
    const [showPassword, setShowPassword] = useState(false);

    const { control, handleSubmit, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: {
            username: '',
            password: '',
        }
    });

    const onSubmit = (values) => login(values);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <Controller
                name="username"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        style={{ border: error ? '1px solid red' : 'none' }}
                        className="mb-5"
                        autoComplete="off"
                        label="Username"
                        error={!!errors.username}
                        helperText={errors.username ? errors.username.message : ''}
                    />
                )}
            />
            <Controller
                name="password"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        className="mb-5"
                        label="Password"
                        style={{ border: error ? '1px solid red' : 'none' }}
                        type={showPassword ? 'text' : 'password'}
                        error={!!errors.password}
                        helperText={errors.password ? errors.password.message : ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        className="text-neo"
                                        onClick={() => { setShowPassword(!showPassword) }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
            <Button
                className="bg-[#39cf9e] text-black mt-10 h-12 login-button"
                type="submit"
                disabled={!isValid}
            >
                Login
            </Button>
        </form>
    );
};

export default LoginForm;
