export const coinList = [
    {
        "proName": "BINANCE:BTCUSDT",
    },
    {
        "proName": "BINANCE:ETHUSDT",
    },
    {
        "proName": "BINANCE:SOLUSDT",
    },
    {
        "proName": "BINANCE:BNBUSDT",
    },
    {
        "proName": "BINANCE:AVAXUSDT",
    },
    {
        "proName": "BINANCE:ADAUSDT",
    },
    {
        "proName": "BINANCE:DOTUSDT",
    },
    {
        "proName": "BINANCE:DOGEUSDT",
    },
]
