import {API_URL} from "../../constants";
import api from "../../api";
import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';

export const createSignalStrategyConfig = createAsyncThunk(
    'createSignalStrategyConfig',
    async (data, {dispatch}) => {
        try {
            const res = await api.post(`${API_URL}/signalstrategy/lego`, data)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const getSignalStrategies = createAsyncThunk(
    'getSignalStrategies',
    async () => {
        try {
            const res = await api.get(`${API_URL}/signalstrategy/lego`)
            return res.data

        } catch (error) {
            console.error(error)
        }
    }
)

export const deleteSignalStrategy = createAsyncThunk(
    "deleteSignalStrategy",
    async (id, {rejectWithValue}) => {
        try {
            const res = await api.delete(`${API_URL}/signalstrategy/lego/${id}`)
            return res.data
        } catch (error) {
            console.log(error)

            return rejectWithValue(id);
        }
    }
)

export const editSignalStrategy = createAsyncThunk(
    'editSignalStrategy',
    async ({id, data}, {dispatch}) => {
        try {
            const res = await api.put(`${API_URL}/signalstrategy/lego/${id}`, data)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

const signalStrategyAdapter = createEntityAdapter({})
export const signalStrategySelector = (state) => state.signalStrategy
export const {selectAll: signalsList, selectById: signalsListSelector} = signalStrategyAdapter.getSelectors(signalStrategySelector)
const signalStrategy = createSlice({
    name: "signalStrategy",
    initialState: signalStrategyAdapter.getInitialState({
        isLoadingStrategy: true,
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createSignalStrategyConfig.pending, (state, action) => {
                state.isLoadingStrategy = true
            })
            .addCase(createSignalStrategyConfig.fulfilled, (state, action) => {
                signalStrategyAdapter?.addOne(state, action.payload)
                state.isLoadingStrategy = false
            })
            .addCase(createSignalStrategyConfig.rejected, (state, action) => {
                state.isLoadingStrategy = false
            })
            .addCase(getSignalStrategies.pending, (state, action) => {
                state.isLoadingStrategy = true
            })
            .addCase(getSignalStrategies.fulfilled, (state, action) => {
                signalStrategyAdapter?.setAll(state, action?.payload)
                state.isLoadingStrategy = false
            })
            .addCase(getSignalStrategies.rejected, (state, action) => {
                state.isLoadingStrategy = false
            })
            .addCase(deleteSignalStrategy.pending, (state, action) => {
                state.isLoadingStrategy = true
            })
            .addCase(deleteSignalStrategy.fulfilled, (state, action) => {
                signalStrategyAdapter.removeOne(state, action.payload.id)
                state.isLoadingStrategy = false
            })
            .addCase(deleteSignalStrategy.rejected, (state, action) => {
                state.isLoadingStrategy = false
            })
            .addCase(editSignalStrategy.pending, (state, action) => {
                state.isLoadingStrategy = true
            })
            .addCase(editSignalStrategy.fulfilled, (state, action) => {
                signalStrategyAdapter.updateOne(state, {id: action.payload.id, changes: action.payload})
                state.isLoadingStrategy = false
            })
            .addCase(editSignalStrategy.rejected, (state, action) => {
                state.isLoadingStrategy = false
            })
    }
})


export default signalStrategy.reducer