import React, { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Header from '../Header/Header'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar
}))

const Layout = () => {

  const accessToken = localStorage.getItem('access_token')

  useEffect(() => {
    document.addEventListener('wheel', handleScrollChange)

    return () => {
      document.removeEventListener('wheel', handleScrollChange)
    }
  }, [])

  const handleScrollChange = (event) => {
    if (document.activeElement.type === 'number') {
      event.preventDefault()
      document.activeElement.blur()
    }
  }

  return (
    <>
     {accessToken && <Header />} 
      <div className="layout">
        <div className="main">
        {accessToken &&  <DrawerHeader />}          
          <Suspense fallback={<p>Loading...</p>}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  )
}

export default Layout
