import {exchangeEntities} from "./consts"

const LandingExchange = () => {
    return (
        <section className="landing-exchange">
            <div className="container">
                <div className="landing-exchange-wrapper">
                    <div className="exchange-content">
                        <h2>Manage all your exchange accounts <span className="highlight">in one place</span></h2>
                        <p>Track and unify your trading data across all Exchanges, simplify your strategy.</p>
                    </div>
                    <div className="exchanges">
                        {exchangeEntities?.map(item => (
                            <div className={`exchange-item ${item.name !== "Binance" ? 'img-overlay' : ''}`} key={item.name}>
                                <img src={item.path} alt={item.name} />
                                <span className="item-type">{item.type}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingExchange;