import { useState } from "react";
import history from "../../history"
import logo from "../../assets/images/brand-logo.png"
import PersonIcon from '@mui/icons-material/Person';
import { menuItems } from './consts'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom'

const LandingHeader = () => {
    const [open, setOpen] = useState(false);

    return (
        <header className="landing-header menu-area">
            <div className="container">
                <div className="landing-header-area">
                    <div className="logo">
                        <a href="/">
                            <img src={logo} alt="Botico" />
                        </a>
                    </div>
                    <div className={`navbar ${open ? 'mobile-nav' : ''}`}>
                        {open ? (
                            <button
                                onClick={() => setOpen(false)}
                                className="navbar-close"
                            >
                                <span>Close</span>
                                {/* <CloseIcon /> */}
                            </button>
                        ) : null}
                        <ul className="navbar-wrapp">
                            {menuItems.map(item => (
                                <li key={item}>
                                    <a href={`#${item}`}>{item}</a>
                                </li>
                            ))}
                            <li>
                                <Link to="/career">Career</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="navigate">
                        <button
                            onClick={() => history.push('/login')}
                            className="lp-login-btn"
                        >
                            Login
                            <PersonIcon />
                        </button>
                        <button
                            onClick={() => setOpen(true)}
                            className="lp-login-btn menu"
                        >
                            <MenuIcon />
                        </button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default LandingHeader;