import {API_URL} from "../../constants";
import api from "../../api";
import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';

export const getExchange = createAsyncThunk(
    'getExchange',
    async (_, {dispatch}) => {
        try {
            const res = await api.get(`${API_URL}/exchange`)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const addExchange = createAsyncThunk(
    'addExchange',
    async (data, {dispatch}) => {
        try {
            const res = await api.post(`${API_URL}/exchange`, { name: data })
            console.log(res)

            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const deleteExchange = createAsyncThunk(
    'deleteExchange',
    async (id, {dispatch, rejectWithValue}) => {
        try {
            await api.delete(`${API_URL}/exchange/${id}`)
            return id
        } catch (error) {
            if (!error.response) {
                throw error;
            }

            return rejectWithValue(id);
        }
    }
)

const collectorAdapter = createEntityAdapter({})
export const exchangeSelectorAdapter = (state) => state?.exchange
export const {selectAll: exchangeSelector} = collectorAdapter.getSelectors(exchangeSelectorAdapter)

const exchange = createSlice({
    name: "exchange",
    initialState: collectorAdapter.getInitialState({
        isLoading: true,
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getExchange.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getExchange.fulfilled, (state, action) => {
                collectorAdapter?.setAll(state, action?.payload)
                state.isLoading = false
            })
            .addCase(getExchange.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(addExchange.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(addExchange.fulfilled, (state, action) => {
                collectorAdapter?.addOne(state, action.payload)
                state.isLoading = false
            })
            .addCase(addExchange.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(deleteExchange.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteExchange.fulfilled, (state, action) => {
                collectorAdapter.removeOne(state, action.payload.id)
                state.isLoading = false
            })
            .addCase(deleteExchange.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export default exchange.reducer;



