import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom'
import { careerPositions } from './consts';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const Career = () => {
    return (
        <div className="career">
            <div className="container career-wrapper">
                <div className="career-head-row">
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link color="inherit" to="/">Home</Link>
                        <Link to="/career" className='text-neo'> Career</Link>
                    </Breadcrumbs>

                    <div>
                        <a href="https://www.linkedin.com/company/botico" target='_blank'>
                            <LinkedInIcon />
                        </a>
                        <a href="https://x.com/BoticoApp" target='_blank'>
                            <TwitterIcon />
                        </a>
                    </div>
                </div>
                <div className="career-wrapper">
                    <div className="career-titlebox">
                        <h1 className='highlight'>Join Botico</h1>
                        <p>{`At Botico, we're transforming the landscape of automated trading with state-of-the-art technology and groundbreaking strategies. Our vibrant team of visionary professionals is committed to pushing the boundaries of financial technology and optimizing trading across cryptocurrency, forex, and stock markets, setting the highest standards in the industry. We cultivate a collaborative environment where every idea matters and every team member contributes to our collective success. Become a part of Botico and make an impact in the world of automated trading.`}</p>
                    </div>
                    <div className="work-at-botico">
                        <h2 className='highlight'>Why Work at Botico?</h2>
                        <ul>
                            <li><strong>Impactful Projects</strong>: Shape the future of financial technology with cutting-edge automated trading solutions.</li>
                            <li><strong>High Growth Potential</strong>: Join a Startup on an accelerated growth trajectory, with opportunities for career advancement and future profit-sharing.</li>
                            <li><strong>Continuous Learning</strong>: Gain insights from industry leaders and access mentorship and training programs.</li>
                            <li><strong>Flexible Work Environment</strong>: Enjoy remote work options and flexible hours that fit your lifestyle.</li>
                            <li><strong>Collaborative Culture</strong>: Thrive in a supportive and inclusive environment that encourages creativity and innovation.</li>
                        </ul>
                    </div>
                    <div className="we-offer">
                        <h2 className='highlight'>What We Offer</h2>
                        <ul>
                            <li><strong>Future Profit Sharing</strong>- Be part of our growth and success with the potential for profit-sharing.</li>
                            <li><strong>Learning and Development</strong>- Access to training programs and mentorship from industry experts.</li>
                            <li><strong>Remote Work</strong>- Flexible working conditions to maintain a healthy work-life balance.</li>
                            <li><strong>Inclusive Culture</strong>- Work in an environment that values diversity and encourages innovation.</li>
                        </ul>
                    </div>
                    
                    <div className="positions">
                        <h2 className='highlight'>Positions</h2>
                        {careerPositions.map(position => (
                            <div key={position.name} className={`position-item ${position.open ? 'open-position' : 'closed-position'}`}>

                                {position.open ? (
                                    <div className='posioton-item-headline'>
                                        <h3 className='highlight '>{position.name}</h3>
                                        <p>{position.deadline}</p>
                                    </div>
                                ) : (
                                    <div className='posioton-item-headline'>
                                        <div className='flex'>
                                            <h3 className='highlight '>{position.name}</h3>
                                            <div className="closed-badge"><span>closed</span></div>
                                        </div>
                                        <p>{position.deadline}</p>
                                    </div>
                                )}
                                <div><strong>Responsibilities</strong>: {position.responsibilities}</div>
                                <div><strong>Requiriments</strong>: {position.requirements}</div>
                                {position.open && <div><span>{position.touch}</span> <a className='highlight' href="mailto:career@botico.app">{'career@botico.app'}</a></div>}
                                {position.preferred && <div><strong>Preferred</strong>: {position.preferred}</div>}
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Career;