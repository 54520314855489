import { useState } from 'react';
import { productTabs } from './consts';

const LandingProduct = () => {
    const [activeTab, setActiveTab] = useState('tab-1');

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <section id='About' className="product">
            <div className="container">
                <div className="product-wrapper">
                    <h2>About</h2>
                    <div className="product-row">
                        <div className="product-content">
                            <p>Welcome to Botico, your gateway to intelligent trading and investing. Our mission is to empower traders and investors of all levels with a cutting-edge platform that amplifies their trading potential, while providing advanced tools and features to maximize returns and minimize risks.</p>
                            <p>Join the Botico community today and discover a more intuitive, efficient, and profitable way to trade and invest.</p>
                        </div>
                        <div className="product-tabs">
                            <div className="tab-header">
                                {productTabs.map((tab) => (
                                    <div
                                        key={tab.id}
                                        className={`tab-link ${activeTab === tab.id ? 'active' : ''}`}
                                        onClick={() => handleTabClick(tab.id)}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </div>
                            {productTabs.map((tab) => (
                                <div
                                    key={tab.id}
                                    className={`tab-content ${activeTab === tab.id ? 'active' : ''}`}
                                    id={tab.id}
                                >
                                    {tab.content}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingProduct;
