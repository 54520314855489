import React from 'react';
import './styles.scss'
import history from "../../history";
import {Button} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';

const ErrorPage = () => {
    return (
        <div className="flex justify-center items-center h-screen w-full bg-primary-black">
            <div className="border border-neo rounded-2xl flex flex-col p-5 max-w-screen-md  error-section">
                <div className="flex flex-col items-center justify-center ">
                    <span className="text-neo text-5xl mb-4">Oops!</span>
                    <h1 className="capitalize text-3xl mb-4">The page you are looking for could not be found.</h1>
                    <h3 className="capitalize text-1xl mb-4">The requested page is missing. Please check the URL or use the navigation menu.</h3>
                    {/*<img className="error-botico-logo" src={boticoGold} alt="Botico" />*/}
                    <Button
                        variant="contained"
                        className="bg-neo "
                        onClick={() => {
                            history.push('/')
                        }}
                    >
                       <HomeIcon />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
