import Welcome from "./Welcome"
import LandingHeader from "./LandingHeader"
import LandingProduct from "./LandingProduct"
import LandingEarn from "./LandingEarn"
import LandingExchange from "./LandingExchange"
import LandingFAQ from "./LandingFAQ"
import LandingFooter from "./LandingFooter"


const LandingPage = () => {

    return (
        <div className="landing-page">
            <LandingHeader />
            <Welcome />
            <LandingProduct />
            <LandingEarn />
            <LandingExchange />
            <LandingFAQ />
            <LandingFooter />
        </div>
    )   
}

export default LandingPage