import twitter from '../../assets/images/landing-page/twitter.png'
import telegram from '../../assets/images/landing-page/telegram.png'
import linkedin from '../../assets/images/landing-page/linkedin.png'
import discord from '../../assets/images/landing-page/discord.png'
import instagram from '../../assets/images/landing-page/instagram.png'

const Welcome = () => {
    return (
        <section className="welcome banner-bg">
            <div className="container">
                <div className="welcome-wrapper">
                    <div className="banner-content">
                        <h2 className="title">Bringing Optimal Trading Intelligence <br /> <span className='highlight'>Coining Opportunities</span></h2>
                        <p>Botico is a trading orchestration toolset that empowers users to create custom signals, define risk metrics, test, automate, and track performance, copy and optimize trading strategy, all in one place.</p>
                        <div className="social-networks">
                            <a href="https://x.com/BoticoApp" target="_blank">
                                <img src={twitter} alt="twitter" />
                            </a>
                            <a href="https://t.me/botico_live" target="_blank">
                                <img src={telegram} alt="telegram" />
                            </a>
                            <a href="https://www.linkedin.com/company/botico" target="_blank">
                                <img src={linkedin} alt="linkedin" />
                            </a>
                            <a href="https://www.instagram.com/botico_official" target="_blank">
                                <img src={instagram} alt="instagram" />
                            </a>
                            <a href="#" target="_blank">
                                <img src={discord} alt="discord" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Welcome;