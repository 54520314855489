import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import { headerMenuItem } from './headerMenuList'

const HeaderMenu = () => {
  const [hoveredItem, setHoveredItem] = useState(null)

  const handleMouseEnter = (index) => {
    setHoveredItem(index)
  }

  const handleMouseLeave = () => {
    setHoveredItem(null)
  }


  return (
    <nav className="header-menu">
      {headerMenuItem.map((item, index) => (
        <div
          className="header-menu-wrapper"
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink
            to={item.path}
            activeClassName="active"
          >
            {item.label}
          </NavLink>
          {hoveredItem === index && item.subItems && (
            <div
              className="submenu"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {item.subItems.map((subItem, subIndex) => (
                <MenuItem key={subIndex}>
                  <NavLink to={subItem.path}>{subItem.label}</NavLink>
                </MenuItem>
              ))}
            </div>
          )}
        </div>
      ))}
    </nav>
  )
}

export default HeaderMenu
