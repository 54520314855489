import React, { useEffect } from "react";
import AppRoutes from './AppRouters';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from "react-toastify";
import { darkTheme } from "./settings";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const App = () => {

    useEffect(() => {
        //temporary solution for fixing google tanslate issue on page
        if (typeof Node === 'function' && Node.prototype) {
            const originalRemoveChild = Node.prototype.removeChild;
            Node.prototype.removeChild = function(child) {
              if (child.parentNode !== this) {
                if (console) {
                  console.error('Cannot remove a child from a different parent', child, this);
                }
                return child;
              }
              return originalRemoveChild.apply(this, arguments);
            }
          
            const originalInsertBefore = Node.prototype.insertBefore;
            Node.prototype.insertBefore = function(newNode, referenceNode) {
              if (referenceNode && referenceNode.parentNode !== this) {
                if (console) {
                  console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
                }
                return newNode;
              }
              return originalInsertBefore.apply(this, arguments);
            }
          }
    }, []);

    return (
        <div className="botico-main">
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
                <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    <ToastContainer
                        limit={5}
                        autoClose={3000}
                        theme="dark"
                    />
                    <AppRoutes />
                </ThemeProvider>
            </LocalizationProvider>
        </div>
    );
}

export default App;
