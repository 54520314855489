import { useState } from 'react';
import api from "../../api"
import { API_URL } from '../../constants';
import { Controller, useForm } from 'react-hook-form';import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { TextField, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import donate from "../../assets/images/landing-page/donation.png"
import btc from "../../assets/images/landing-page/btc.svg"
import eth from "../../assets/images/landing-page/eth.svg"
import doge from "../../assets/images/landing-page/doge.svg"



const DonateDialog = ({ open, handleClose }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <div className='dialog-row' >
                    <div className="dialog-item-row">
                        <span className='type'>Bitcoin</span>
                        <div className="dialog-item">
                            <img src={btc} alt="btc" />
                            <span>{'19ZVS41Z2ADSLgophcr2pLysuSRPH6mBBQ'}</span>
                        </div>
                    </div>

                    <div className="dialog-item-row">
                        <span className='type'>ERC 20</span>
                        <div className="dialog-item">
                            <img src={eth} alt="btc" />
                            <span>{'0x3f6493db00a0f1ce52c88e648acde696de8e80c0'}</span>
                        </div>
                    </div>
                    <div className="dialog-item-row">
                        <span className='type'>Dogecoin</span>
                        <div className="dialog-item">
                            <img src={doge} alt="btc" />
                            <span>{'DTNbfRcDtSt7DfbJVQkXejFmddSsyNh4RF'}</span>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}


const LandingFooter = () => {
    const [open, setOpen] = useState(false);
    const currentYear = moment().year();

    const schema = yup.object().shape({
        email: yup.string().email('Enter a valid email').required('Email is required'),
        message: yup.string().max(120, 'Message must be at most 120 characters')
    });

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            email: '',
            message: ""
        },
    });

    const onSubmit = async ({ email, message }) => {
        try {
            await api.post(`${API_URL}/beta-request`, { email, message });
            reset();
        } catch (error) {
            console.error('Failed to submit beta request:', error);
        }
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <footer className="landing-footer">
            <div className="container">
                <div className="footer-wrapper">
                    <div className="left-footer">
                        <h3>Address and Contacts</h3>
                        <span>+1 604 861 03 60</span>
                        <span>525 West 8th Avenue, Vancouver, BC, Canada.</span>
                    </div>
                    <div className="right-footer">
                        <div className="subscribe">
                            <h3>Stay ahead of the curve</h3>
                            <p>Reach out to us for Beta version testing access.</p>
                            <div className="subscribe-fields">
                                <form onSubmit={handleSubmit(onSubmit)} className='fields-action'>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                placeholder='Enter email...'
                                                variant="outlined"
                                                margin="normal"
                                                className='subscibe-input'
                                                autoComplete="off"
                                                error={!!errors.email}
                                                helperText={errors.email ? errors.email.message : ''}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="message"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                placeholder='Optional'
                                                variant="outlined"
                                                margin="normal"
                                                multiline
                                                rows={3}
                                                className='subscibe-input'
                                                autoComplete="off"
                                                error={!!errors.message}
                                                helperText={errors.message ? errors.message.message : ''}
                                            />
                                        )}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Send
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="all-rights">All rights reserved &copy; {currentYear}</div>
                    {/* <div className="dontation">
                        <img src={donate} alt="dontaion" />
                        <span onClick={handleClickOpen}>Donate</span>
                    </div> */}
                </div>
            </div>

            <DonateDialog
                open={open}
                handleClose={handleClose}
            />
        </footer>
    )
}

export default LandingFooter;
