import {API_URL} from "../../constants";
import api from "../../api";
import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';

export const getBackTestingCollector = createAsyncThunk(
    'getBackTestingCollector',
    async (_, {dispatch}) => {
        try {
            const res = await api.get(`${API_URL}/backtesting/collector/config`)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const addBackTestingCollector = createAsyncThunk(
    'addBackTestingCollector',
    async (data, {dispatch}) => {
        try {
            const res = await api.post(`${API_URL}/backtesting/collector/config`, data)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }
)

// export const editBackTestingCollector = createAsyncThunk(
//     'editBackTestingCollector',
//     async ({id, data}, {dispatch}) => {
//         try {
//             const res = await api.put(`${API_URL}/backtesting/collector/config/${id}`, data)
//             return res.data
//         } catch (error) {
//             console.log(error)
//         }
//     }
// )

export const deleteBackTestingCollector = createAsyncThunk(
    'deleteBackTestingCollector',
    async (id, {dispatch, rejectWithValue}) => {
        try {
            await api.delete(`${API_URL}/backtesting/collector/config/${id}`)
            return id
        } catch (error) {
            if (!error.response) {
                throw error;
            }

            return rejectWithValue(id);
        }
    }
)

const collectorAdapter = createEntityAdapter({})
export const backTestingCollectorSelector = (state) => state?.backTestingCollector
export const {selectAll: btCollectorsSelector, selectById: btCollectorsBySelector} = collectorAdapter.getSelectors(backTestingCollectorSelector)

const backTestingCollector = createSlice({
    name: "backTestingCollector",
    initialState: collectorAdapter.getInitialState({
        isLoading: true,
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBackTestingCollector.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getBackTestingCollector.fulfilled, (state, action) => {
                collectorAdapter?.setAll(state, action?.payload)
                state.isLoading = false
            })
            .addCase(getBackTestingCollector.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(addBackTestingCollector.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(addBackTestingCollector.fulfilled, (state, action) => {
                collectorAdapter?.addOne(state, action.payload)
                state.isLoading = false
            })
            .addCase(addBackTestingCollector.rejected, (state, action) => {
                state.isLoading = false
            })
            // .addCase(editBackTestingCollector.pending, (state, action) => {
            //     state.isLoading = true
            // })
            // .addCase(editBackTestingCollector.fulfilled, (state, action) => {
            //     collectorAdapter.updateOne(state, {id: action.payload.id, changes: action.payload})
            //     state.isLoading = false
            // })
            // .addCase(editBackTestingCollector.rejected, (state, action) => {
            //     state.isLoading = false
            // })
            .addCase(deleteBackTestingCollector.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteBackTestingCollector.fulfilled, (state, action) => {
                collectorAdapter.removeOne(state, action.payload.id)
                state.isLoading = false
            })
            .addCase(deleteBackTestingCollector.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export default backTestingCollector.reducer;



