import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import logo from '../../assets/images/brand-logo.png'
import { TickerTape } from 'react-ts-tradingview-widgets'
import { coinList } from './coinList'
import { Avatar } from '@mui/material'
import HeaderMenu from './HeaderMenu/HeaderMenu'
import AccountMenu from '../Menu/AccountMenu'

import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { accountSelector, getAccount } from '../../redux/slices/accountSlice'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))
const Header = ({ handleDrawerOpen, handleDrawerClose, open }) => {
  const dispatch = useDispatch();
  const account = useSelector(accountSelector);

  const [accountAnchor, setAccountAnchor] = useState(null)
  const isAccountMenuOpen = Boolean(accountAnchor)

  useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  const handleOpenAccountMenu = (event) => {
    setAccountAnchor(event.currentTarget)
  }
  const handleCloseAccountMenu = () => {
    setAccountAnchor(null)
  }


  return (
    <Box className="header-box">
      <AppBar className="app-header-bar" position="fixed" open={open}>
        <Toolbar>
          <div className="flex items-center w-full justify-between">
            <div className="logo w-24 ">
              <img src={logo} alt="VST Botico" />
            </div>
            <HeaderMenu />
            <div className="w-3/6 mr-4">
              <TickerTape colorTheme="dark" symbols={coinList} isTransparent />
            </div>
            <div className="flex">
              <IconButton
                onClick={handleOpenAccountMenu}
                size="small"
              >
                <Avatar />
              </IconButton>
              <AccountMenu account={account} open={isAccountMenuOpen} anchorEl={accountAnchor} onClose={handleCloseAccountMenu} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header
