import { API_URL } from "../../constants";
import api from "../../api";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getBotInstance = createAsyncThunk(
    'botInstance/getBotInstance',
    async (_, { rejectWithValue }) => {
        try {
            const res = await api.get(`${API_URL}/bot/instance/config`);
            return res.data.reverse();
        } catch (error) {
            console.log(error);
            return rejectWithValue(error.message);
        }
    }
);

export const deleteBotInstance = createAsyncThunk(
    'botInstance/deleteBotInstance',
    async (id, { rejectWithValue }) => {
        try {
            await api.delete(`${API_URL}/bot/instance/config/${id}`);
            return id;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(id);
        }
    }
);


const botInstance = createSlice({
    name: 'botInstance',
    initialState: {
        data: [],
        loading: false,
        error: null
    },
    reducers: {
        resetData: (state) => {
            state.data = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBotInstance.pending, (state) => {
                state.loading = true;
            })
            .addCase(getBotInstance.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                state.error = null;
            })
            .addCase(getBotInstance.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteBotInstance.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteBotInstance.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter(item => item.id !== action.payload);
                state.error = null;
            })
            .addCase(deleteBotInstance.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
});

export const { resetData } = botInstance.actions;
export const botInstanceSelector = (state) => state.botInstance;

export default botInstance.reducer;
